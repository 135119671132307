import { clsx } from "clsx";
import { DropdownMenuItemColor } from "./FaDropdownMenuItem";

type GetDropdownMenuItemClassesParams = {
    hasIcon: boolean;
    color: DropdownMenuItemColor;
    className?: string;
};

export function getDropdownMenuItemClasses(params: GetDropdownMenuItemClassesParams): string {
    return clsx(
        "fa-dropdown-menu-item",
        {
            "fa-dropdown-menu-item--color-light": params.color === "light",
            "fa-dropdown-menu-item--color-primary": params.color === "primary",
            "fa-dropdown-menu-item--color-danger": params.color === "danger",
            "fa-dropdown-menu-item--has-icon": params.hasIcon,
        },
        params.className
    );
}
