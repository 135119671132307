import * as PortalPrimitives from "@radix-ui/react-portal";
import clsx from "clsx";
import { CSSProperties, MouseEvent, forwardRef, useEffect, useState } from "react";
import "./faPopperOverlay.scss";

type FaPopperOverlayProps = {
    open?: boolean;
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;

    portalTarget?: HTMLElement;
};

const fadeDuration = 500;

export const FaPopperOverlay = forwardRef<HTMLDivElement, FaPopperOverlayProps>((props, forwardedRef) => {
    const [mounted, setMounted] = useState(props.open);
    if (props.open && !mounted) {
        setMounted(true);
    }

    useEffect(() => {
        if (props.open) {
            return;
        }

        const timer = setTimeout(() => {
            setMounted(false);
        }, fadeDuration);

        return () => {
            clearTimeout(timer);
        };
    }, [props.open]);

    // the first condition is required because otherwise the content's portal gets added to the body
    // before this one does, resulting in the fade-plane covering the content
    if (props.open || mounted) {
        return (
            <PortalPrimitives.Portal
                ref={forwardedRef}
                onPointerDown={props.onClick}
                style={{ "--fa-popper-fade-duration": `${fadeDuration}ms` } as CSSProperties}
                className={clsx("fa-popper-overlay", {
                    "fa-popper-overlay--visible": props.open,
                })}
                container={props.portalTarget}
            />
        );
    }

    return null;
});
