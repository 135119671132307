/**
 * get the element where a portaled-component should be attached to
 * @param trigger - element that opens/closes the overlay
 */
export function getPortalTarget(trigger: HTMLElement | null): HTMLElement {
    if (!trigger) {
        return document.body;
    }

    const target = trigger.closest("[data-fa-popover-target]");
    if (target && target instanceof HTMLElement) {
        return target;
    }

    return document.body;
}
