import { createScopedContext } from "@/util/context";

type FaDropdownMenuContextProps = {
    close: () => void;

    portalTarget: HTMLElement;
    setTrigger: (element: HTMLElement | null) => void;
};

export const [FaDropdownMenuContext, useFaDropdownMenuContext] =
    createScopedContext<FaDropdownMenuContextProps>("FaDropdownMenuContext");
