import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import { useFaDropdownMenuContext } from "@/components/FaDropdownMenu/FaDropdownMenuContext";
import "./faDropdownMenuContent.scss";

type FaDropdownMenuContentProps = DropdownPrimitives.MenuContentProps;

export const FaDropdownMenuContent = (props: FaDropdownMenuContentProps) => {
    const { children, sideOffset, collisionPadding, className, ...contentProps } = props;

    const { portalTarget } = useFaDropdownMenuContext();

    return (
        <DropdownPrimitives.Portal container={portalTarget}>
            <DropdownPrimitives.Content
                className={clsx("fa-dropdown-menu-content", className)}
                sideOffset={sideOffset ?? 8}
                collisionPadding={collisionPadding ?? 16}
                {...contentProps}>
                {children}
            </DropdownPrimitives.Content>
        </DropdownPrimitives.Portal>
    );
};
