import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import { MouseEvent, useState } from "react";
import { FaDropdownMenuContext } from "@/components/FaDropdownMenu/FaDropdownMenuContext";
import { FaPopperOverlay } from "@/components/FaPopperOverlay/FaPopperOverlay";
import { getPortalTarget } from "@/util/overlayUtils";

type FaDropdownMenuRootProps = DropdownPrimitives.DropdownMenuProps & {
    onOverlayClick?: (e: MouseEvent<HTMLDivElement>) => void;
};

export function FaDropdownMenuRoot(props: FaDropdownMenuRootProps) {
    const { onOverlayClick, onOpenChange, open: controlledOpen, ...rootProps } = props;

    const [open, setOpen] = useState(false);
    const [trigger, setTrigger] = useState<HTMLElement | null>(null);

    const handleOverlayClick = (event: MouseEvent<HTMLDivElement>) => {
        setOpen(false);
        onOverlayClick?.(event);
        event.stopPropagation();
    };

    return (
        <FaDropdownMenuContext.Provider
            value={{
                close: () => setOpen(false),
                portalTarget: getPortalTarget(trigger),
                setTrigger,
            }}>
            <DropdownPrimitives.Root
                {...rootProps}
                modal={false}
                open={controlledOpen ?? open}
                onOpenChange={value => {
                    setOpen(value);
                    onOpenChange?.(value);
                }}>
                <FaPopperOverlay
                    open={controlledOpen ?? open}
                    onClick={handleOverlayClick}
                    portalTarget={getPortalTarget(trigger)}
                />
                {props.children}
            </DropdownPrimitives.Root>
        </FaDropdownMenuContext.Provider>
    );
}
