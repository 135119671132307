import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import { ReactNode } from "react";
import { DropdownMenuItemColor } from "@/components/FaDropdownMenu/FaDropdownMenuItem/FaDropdownMenuItem";
import { getDropdownMenuItemClasses } from "@/components/FaDropdownMenu/FaDropdownMenuItem/FaDropdownMenuItemUtils";
import { FaDropdownMenuItemIndicator } from "@/components/FaDropdownMenu/FaDropdownMenuItemIndicator/FaDropdownMenuItemIndicator";
import "./faDropdownMenuRadioItem.scss";

type FaDropdownMenuRadioItemOwnProps = DropdownPrimitives.MenuRadioItemProps & {
    icon?: IconProp;
    /**
     * @default "light"
     */
    color?: DropdownMenuItemColor;
    /**
     * An optional tooltip. Set to `undefined` to not show a tooltip
     * @default undefined
     */
    tooltip?: ReactNode;
};

type FaDropdownMenuRadioItemProps = FaDropdownMenuRadioItemOwnProps &
    Omit<DropdownPrimitives.MenuRadioItemProps, keyof FaDropdownMenuRadioItemOwnProps>;

export function FaDropdownMenuRadioItem(props: FaDropdownMenuRadioItemProps) {
    const { icon, color = "light", children, className, ...labelProps } = props;

    const iconComponent = icon ? (
        <span className={"fa-dropdown-menu-item__icon"}>
            <FontAwesomeIcon icon={icon} />
        </span>
    ) : null;

    return (
        <DropdownPrimitives.RadioItem
            className={getDropdownMenuItemClasses({
                hasIcon: !!icon,
                color,
                className: clsx("fa-dropdown-menu-radio-item", className),
            })}
            {...labelProps}>
            <div className="fa-dropdown-menu-radio-item__label-slot">
                {iconComponent}
                {children}
            </div>
            <span className="fa-dropdown-menu-radio-item__indicator-slot">
                <FaDropdownMenuItemIndicator className="fa-dropdown-menu-radio-item__indicator" />
            </span>
        </DropdownPrimitives.RadioItem>
    );
}
