import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import { useFaDropdownMenuContext } from "@/components/FaDropdownMenu/FaDropdownMenuContext";
import "./faDropdownMenuTrigger.scss";

type FaDropdownMenuTriggerProps = Omit<DropdownPrimitives.DropdownMenuTriggerProps, "asChild"> & {
    /**
     * Decides, if the trigger should take over it's child or render as a separate <button> element.
     * If true the child element gets taken.
     * @default true
     */
    asChild?: boolean;
};

export const FaDropdownMenuTrigger = (props: FaDropdownMenuTriggerProps) => {
    const { children, className, asChild = true, ...triggerProps } = props;

    const { setTrigger } = useFaDropdownMenuContext();

    return (
        <DropdownPrimitives.Trigger
            className={clsx("fa-dropdown-menu-trigger", className)}
            {...triggerProps}
            ref={setTrigger}
            asChild={asChild}>
            {children}
        </DropdownPrimitives.Trigger>
    );
};
